import React from 'react';

import logo from '../logo.png';

export function Home() {
  return (
    <div className="section homepage">
      <div className="page-wrapper">
        <div className="logo">
          <img src={logo} alt="Sylvia Medina - Makeup Artist" />
        </div>
      </div>
    </div>
  );
}
