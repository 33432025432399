import React from 'react';

import bio from "../assets/images/bio.jpg"

export function About() {
  return (
    <div className="section about">
      <div className="page-wrapper">
        <div
          className="photo"
          style={{ backgroundImage: `url(${bio}`, backgroundPosition: "center center", backgroundSize: "cover" }}
        />
        <div className="bio">
          <p>Sylvia Medina, describes her style as clean and fresh, with a unique edge. "Every one is unique." says Sylvia. "I love to enhance everyones natural and individual beauty. I know how important it is to feel like yourself--just more polished and beautiful."</p>
          <p>Sylvia balances her passion for the art of makeup with caring for her family. A former Los Angeles local, who started her career in the film industry, covering fashion, commercial, print, editorial, bridal and pageant makeup.</p>
          <p>Her creativity, attention to detail and her ability to bring out an individual's best features, make for an ideal makeup artist. Having catered to hundreds of brides and her parties, models, beauty pageant contestants, actors and more has left her highly sought after by local wedding gurus and clients alike.</p>
          <p>Sylvia offers her services by appointment to her devoted clientele and is available to travel nationally.</p>
        </div>
      </div>
    </div>
  );
}