import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { Home } from "./Home";
import { About } from "./About";
import { Portfolio } from "./Portfolio";
import { Contact } from "./Contact";

export const FullPageWrapper = () => (
  <ReactFullpage
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Home />
          <About />
          <Portfolio />
          <Contact />
        </ReactFullpage.Wrapper>
      );
    }}
  />
);