import React from "react";

import logo from "../name-logo.svg";

export const Contact = () => (
  <div className="section contact">
    <div className="page-wrapper">
      <div className="contact">
        <img className="contact-logo" src={logo} alt="Sylvia Medina" />
        <div>
          <p>480-295-6010</p>
          <p>sylvia.f.medina@gmail.com</p>
        </div>
      </div>
    </div>
  </div>
);
