import React, { Component } from 'react';

import { FullPageWrapper } from './pages/FullPageWrapper';

import './App.css';

class App extends Component {
  componentDidMount() {
    window.scrollTo(0,1);
  };
  render() {
    return (
      <div className="app">
        <FullPageWrapper />
      </div>
    );
  }
}

export default App;
