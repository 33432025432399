import React from 'react';
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

import image01 from "../assets/images/gallery/01.jpg";
import image02 from "../assets/images/gallery/02.jpg";
import image03 from "../assets/images/gallery/03.jpg";
import image04 from "../assets/images/gallery/04.jpg";
import image05 from "../assets/images/gallery/05.jpg";
import image06 from "../assets/images/gallery/06.jpg";
import image07 from "../assets/images/gallery/07.jpg";
import image08 from "../assets/images/gallery/08.jpg";
import image09 from "../assets/images/gallery/09.jpg";
import image10 from "../assets/images/gallery/10.jpg";
import image11 from "../assets/images/gallery/11.jpg";
import image12 from "../assets/images/gallery/12.jpg";
import image13 from "../assets/images/gallery/13.jpg";
import image14 from "../assets/images/gallery/14.jpg";
import image15 from "../assets/images/gallery/15.jpg";
import image16 from "../assets/images/gallery/16.jpg";
import image17 from "../assets/images/gallery/17.jpg";
import image18 from "../assets/images/gallery/18.jpg";
import image19 from "../assets/images/gallery/19.jpg";
import image20 from "../assets/images/gallery/20.jpg";

export function Portfolio() {
  const images = [
    {
      original: image01
    },
    {
      original: image02
    },
    {
      original: image03
    },
    {
      original: image04
    },
    {
      original: image05
    },
    {
      original: image06
    },
    {
      original: image07
    },
    {
      original: image08
    },
    {
      original: image09
    },
    {
      original: image10
    },
    {
      original: image11
    },
    {
      original: image12
    },
    {
      original: image13
    },
    {
      original: image14
    },
    {
      original: image15
    },
    {
      original: image16
    },
    {
      original: image17
    },
    {
      original: image18
    },
    {
      original: image19
    },
    {
      original: image20
    },
  ];
  return (
    <div className="section portfolio">
        <div className="gallery">
          <ImageGallery
            items={images}
            originalClass="gallery-image"
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
          />
        </div>
      </div>
  );
}
